// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    border: 2px solid red;
    padding: 10px 20px;
    margin: 10px auto; /* Center the button */
    text-decoration: none;
    border-radius: 30px;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    max-width: 400px; /* Limit the width of the button */
    font-size: 20px;
    margin-bottom: 40px;
}

.order-button:hover {
    background-color: red;
    color: #fff;
}

.button-icon {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    margin: 0 10px;
}

.button-text {
    white-space: nowrap; /* Prevents text from wrapping */
}

.left-icon {
    margin-right: auto; /* Push to the left */
}

.right-icon {
    margin-left: auto; /* Push to the right */
}
`, "",{"version":3,"sources":["webpack://./src/views/HourAndPrice/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB,EAAE,sBAAsB;IACzC,qBAAqB;IACrB,mBAAmB;IACnB,6CAA6C;IAC7C,kBAAkB;IAClB,gBAAgB,EAAE,kCAAkC;IACpD,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,WAAW,EAAE,0BAA0B;IACvC,YAAY,EAAE,0BAA0B;IACxC,cAAc;AAClB;;AAEA;IACI,mBAAmB,EAAE,gCAAgC;AACzD;;AAEA;IACI,kBAAkB,EAAE,qBAAqB;AAC7C;;AAEA;IACI,iBAAiB,EAAE,sBAAsB;AAC7C","sourcesContent":[".order-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: transparent;\n    color: white;\n    border: 2px solid red;\n    padding: 10px 20px;\n    margin: 10px auto; /* Center the button */\n    text-decoration: none;\n    border-radius: 30px;\n    transition: background-color 0.3s, color 0.3s;\n    text-align: center;\n    max-width: 400px; /* Limit the width of the button */\n    font-size: 20px;\n    margin-bottom: 40px;\n}\n\n.order-button:hover {\n    background-color: red;\n    color: #fff;\n}\n\n.button-icon {\n    width: 30px; /* Adjust size as needed */\n    height: 30px; /* Adjust size as needed */\n    margin: 0 10px;\n}\n\n.button-text {\n    white-space: nowrap; /* Prevents text from wrapping */\n}\n\n.left-icon {\n    margin-right: auto; /* Push to the left */\n}\n\n.right-icon {\n    margin-left: auto; /* Push to the right */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
