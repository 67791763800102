import React from "react";
import "./index.css";


class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div className="footer vertical-align">
                <div>Copyrights © 2024 ® MONOMORPH LIMITED | All rights reserved</div>
                <div>THE ORIGINAL DAHU HOTPOT FROM CHONGQING</div>
                <div>Flagship In Canada</div>
                <div>PHOTOS SHOWN ARE FOR ILLUSTRATION PURPOSES ONLY. ACTUAL PRODUCT MAY VARY DUE TO PRODUCT
                    ENHANCEMENT.
                </div>
                <div>商品擺盤請以現場實物為主，產品形象圖中的擺盤裝飾物僅供參考。</div>
            </div>
        );
    }
}


export default Index;
