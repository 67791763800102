import React from "react";
import "./index.css";
import DahuLogo from "../../images/DAHU Logo.png";
import DahuTextLogo from "../../images/Dahu_NewLogo_v2_edited.png";
import OriginalLogo from "../../images/Original DAHU HOTPOT.png";
import DahuVerticalLogo from "../../images/Dahu_NewLogo.png";
import InstagramLogo from "../../images/instagram.png";
import XLogo from "../../images/x.png";
import FacebookLogo from "../../images/facebook.png";
import XiaohongshuLogo from "../../images/xiaohongshu.png";
import GoogleMapLogo from "../../images/google map.png";
import GoogleLogo from "../../images/google.png";
import YelpLogo from "../../images/yelp.png";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div className="header">
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap"
                      rel="stylesheet"/>
                <div className="logo-section vertical-align">
                    <div className="logo-images">
                        <img src={DahuLogo} alt="Logo" className="logo-image"/>
                        <img src={DahuTextLogo} alt="Logo" className="logo-image"/>
                        <img src={OriginalLogo} alt="Logo" className="logo-image"/>
                    </div>
                    <div className="logo-text">
                        <div>FLAGSHIP IN CANADA</div>
                    </div>
                    <div className="logo-text-address">
                        <div>47 BALDWIN ST</div>
                    </div>
                    <div className="logo-text-address">
                        <div>DOWNTOWN TORONTO</div>
                    </div>
                </div>

                <div className="nav-section">
                    <nav className="nav">
                        <a href="home">Home</a>
                        <a href="prestige-rewards">Dahu Membership</a>
                        <a href="dahupromotion">Promotions</a>
                        <a href="hoursandprice">Hour & Price</a>
                        {/*<a href="menu">Dahu Menu</a>*/}
                        {/*<a href="drinks">Drinks Menu</a>*/}
                    </nav>
                    <div className="social-icons">
                        <a href="https://order.mrsdigi.com/035411231109003/42644362-8145-518b-a271-3c0afb8fe153"
                           className="cta-button" target="_blank"
                           rel="noopener noreferrer">Order Now</a>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/dahuhotpotca" target="_blank"
                               rel="noopener noreferrer">
                                <img src={InstagramLogo} alt="Instagram" className="social-icon"/>
                            </a>
                            <a href="https://www.twitter.com/dahuhotpotca" target="_blank"
                               rel="noopener noreferrer">
                                <img src={XLogo} alt="X" className="social-icon"/>
                            </a>
                            <a href="https://www.facebook.com/dahuhotpotca" target="_blank"
                               rel="noopener noreferrer">
                                <img src={FacebookLogo} alt="Facebook" className="social-icon"/>
                            </a>
                            <a href="https://www.xiaohongshu.com/user/profile/613d757100000000020236b0?xhsshare=CopyLink&appuid=629f68810000000019029467&apptime=1700531198"
                               target="_blank"
                               rel="noopener noreferrer">
                                <img src={XiaohongshuLogo} alt="Xiaohongshu" className="social-icon"/>
                            </a>
                            <a href="https://maps.app.goo.gl/3uNUdsHwBGm2pxAm7" target="_blank"
                               rel="noopener noreferrer">
                                <img src={GoogleMapLogo} alt="Google Map" className="social-icon"/>
                            </a>
                            <a href="https://g.page/r/Cb7SGkCUc6P0EAI/review" target="_blank"
                               rel="noopener noreferrer">
                                <img src={GoogleLogo} alt="Google" className="social-icon"/>
                            </a>
                            <a href="https://www.yelp.ca/biz/dahu-hotpot-toronto?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)"
                               target="_blank"
                               rel="noopener noreferrer">
                                <img src={YelpLogo} alt="Yelp" className="social-icon"/>
                            </a>
                        </div>
                    </div>

                </div>

                <img src={DahuVerticalLogo} alt="Logo" className="logo-image"/>
            </div>
        );
    }
}


export default Index;
