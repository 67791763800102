import React from "react";
import "./index.css";

import Header from "../Header";
import Footer from "../Footer";


import DahuLogo from "../../images/DAHU Logo.png";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div>
                <Header></Header>
                <div className="body vertical-align"></div>
                <a href="https://order.mrsdigi.com/035411231109003/42644362-8145-518b-a271-3c0afb8fe153"
                   className="order-button" target="_blank" rel="noopener noreferrer">
                    <img src={DahuLogo} alt="Icon" className="button-icon left-icon"/>
                    <div className="button-text">堂食線上訂單 Online Order</div>
                    <img src={DahuLogo} alt="Icon" className="button-icon right-icon"/>
                </a>

                <div
                    className="order-button" target="_blank" rel="noopener noreferrer">
                    <img src={DahuLogo} alt="Icon" className="button-icon left-icon"/>
                    <div className="button-text">
                        午市火鍋套餐 Hotpot Set Lunch <br/> Mon - Fri 11am - 4pm
                    </div>
                    <img src={DahuLogo} alt="Icon" className="button-icon right-icon"/>
                </div>

                <div
                    className="order-button" target="_blank" rel="noopener noreferrer">
                    <img src={DahuLogo} alt="Icon" className="button-icon left-icon"/>
                    <div className="button-text">
                        平日全日任吃火鍋 <br/> Weekdays AYCE
                    </div>
                    <img src={DahuLogo} alt="Icon" className="button-icon right-icon"/>
                </div>

                <div
                    className="order-button" target="_blank" rel="noopener noreferrer">
                    <img src={DahuLogo} alt="Icon" className="button-icon left-icon"/>
                    <div className="button-text">
                        週末午市任吃火鍋 <br/> Weekends LUNCH AYCE
                    </div>
                    <img src={DahuLogo} alt="Icon" className="button-icon right-icon"/>
                </div>

                <div
                    className="order-button" target="_blank" rel="noopener noreferrer">
                    <img src={DahuLogo} alt="Icon" className="button-icon left-icon"/>
                    <div className="button-text">
                        週末晚市任吃火 <br/> Weekends DINNER AYCE
                    </div>
                    <img src={DahuLogo} alt="Icon" className="button-icon right-icon"/>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}


export default Index;
