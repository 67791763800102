import React from "react";
import "./index.css";

import Header from "../Header";
import Footer from "../Footer";

import LanternBackground from "../../images/lantern background.png";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div>
                <Header></Header>

                <div className="membership-body vertical-align">
                    <div className="lantern-section" style={{backgroundImage: `url(${LanternBackground})`}}>
                        <div className="overlay">
                            <h1>Dahu VIP Member</h1>
                            <p>Benefits at A Glance</p>
                            <p>Join Now</p>
                            <p>Earn, redeem</p>
                            <p>enjoy member exclusive benefits</p>
                            <p>and monthly special offers.</p>
                            <a href="disclaimer">
                                <button className="cta-button-terms-and-conditions">Terms and Conditions</button>
                            </a>
                        </div>
                    </div>

                    <h1>MEMBERS EXCLUSIVE OFFER</h1>
                    <p>尊享大虎會員獨家折扣優惠、禮品或升級禮遇！</p>

                    <div className="divider"></div>
                    <div className="red">free birthday all you can eat hotpot</div>
                    <div className="small">Terms and Conditions:</div>
                    <div className="small">One week before your birthday and during your birthday week, valid for a
                        total of
                        two weeks between birthday date.
                    </div>
                    <div className="small">DAHU members celebrating their birthdays will receive one complimentary
                        all-you-can-eat hotpot meal.
                    </div>
                    <div className="small">A minimum of four (4) people per table is required to redeem the free
                        birthday
                        all-you-can-eat meal exclusive member only.
                    </div>
                    <div className="small"> Please present identification to our staff for birthday verification. Not to
                        be
                        combined with other offers.
                    </div>

                    <div className="divider"></div>
                    <div className="red">免費生日任食火鍋</div>
                    <div className="small">條款與條件：</div>
                    <div className="small">在您的生日前一週以及您的生日週期間，共兩週內有效，計算自生日當天起。</div>
                    <div className="small">大虎重慶火鍋會員在慶祝生日時將獲得一份免費的任食火鍋餐。</div>
                    <div className="small">每桌至少需有四（4）人方可兌換免費生日任食火鍋餐，此優惠僅適用於會員。</div>
                    <div className="small"> 請向我們的工作人員出示身份證明以進行生日驗證。不可與其他優惠同時使用。</div>

                    <div className="divider"></div>
                    <div className="divider"></div>

                    <h1>YOU EAT, WE REWARD!</h1>
                    <h1>Tuesday Double Point Day!</h1>
                    <p>Join us at DAHU Hotpot for our exclusive member event on every Tuesday!</p>
                    <p>Every $10 spent on Tuesdays earns you double points.</p>

                    <div className="divider"></div>
                    <div className="red">TUESDAY benefit and rewards</div>
                    <div className="small">Reward double reward point for every $10 spent!</div>
                    <div className="small">For every $10 spent on Tuesday, you will earn TWO (2) DAHU rewards point
                    </div>
                    <div className="small">Each ONE (1) DAHU rewards point is redeemable for $1 store credit</div>
                    <div className="small">Please note that points cannot be exchanged for cash</div>

                    <div className="divider"></div>
                    <div className="red">雙倍獎賞優惠</div>
                    <div className="small">積分兌換：每逢星期二消費 $10 賺取 2 點獎勵積分！</div>
                    <div className="small">每消費 $10，您將獲得1點DAHU獎勵積分。</div>
                    <div className="small">每1點DAHU獎勵積分 可以兌換 $1 的店內消費。</div>
                    <div className="small">請注意，積分不能兌換現金。</div>

                    <div className="divider"></div>
                    <div className="red">$10 = 1 DAHU POINT X 2</div>
                    <div className="small">10 DAHU POINT = $1 DAHU STORE CREDIT</div>

                    <div className="divider"></div>
                    <p>Enjoy our exquisite hotpot and accumulate points for fantastic rewards at DAHU HOTPOT</p>

                </div>

                <Footer></Footer>;
            </div>
        );
    }
}


export default Index;
