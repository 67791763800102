import React from "react";
import "./index.css";

import Header from "../Header";
import Footer from "../Footer";


class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div>
                <Header></Header>
                <div className="body">
                    <div className="disclaimer">
                        Thank you for choosing to dine at DAHU Hotpot Canada. Before you savor our hotpot experience, we
                        kindly request that you review the following Disclaimer, Disclosure and Recommendations:
                        <br/><br/>
                        1. The flagship store of DAHU Hotpot in Canada is a unique and exclusive international brand
                        originating from DAHU Chongqing, China. Renowned for its distinctive qualities, DAHU Hotpot
                        provides a numbing sensation without irritation, a perfect balance of spiciness without dryness,
                        and a fragrant touch without greasiness, resulting in a mellow and lingering taste. It's crucial
                        to note that any brand resembling ours or incorporating additional prefixes or suffixes is not
                        affiliated with us.
                        <br/><br/>
                        2. DAHU Hotpot flagship store is located at 47 Baldwin Street, Toronto, Canada. We maintain no
                        cooperative or business-related affiliations with other local restaurants or businesses. We hold
                        the complete trademark rights for DAHU/DAHU Hotpot/ DA HU Hotpot, and we retain the right to
                        pursue legal responsibility for any individual or entity infringing upon the registered
                        trademark rights of DAHU Hotpot.
                        <br/><br/>
                        3. The only official website in Canada : www.dahuhotpot.ca Official Email : info@dahuhotpot.ca
                        Office social media platform Facebook : https://www.facebook.com/dahuhotpotca/ Instagram
                        :https://www.instagram.com/dahuhotpotca/ Twitter https://www.twitter.com/dahuhotpotca
                        Xiaohongshu (Little Redbook) ID: DAHUHOTPOT
                        <br/><br/>
                        4.Food and Beverage Service Notice: Our restaurant offers all-you-can-eat hot pot and à la carte
                        service experience, and we appreciate your cooperation in maintaining personal hygiene. Kindly
                        adhere to the hygiene guidelines provided to ensure the safety of the food and the well-being of
                        all patrons. Your conscientiousness contributes to a clean and enjoyable dining environment for
                        everyone.
                        <br/><br/>
                        5. All orders are made through our website and will be directed to an external link,
                        order.mrsdigi.com. Once your order has been submitted to order.mrsdigi.com, you will receive a
                        text message for confirmation at the email address or phone number you provided. Failure to
                        confirm or respond to order.mrsdigi.com may lead to the cancellation of the order, and it will
                        not be processed.
                        <br/><br/>
                        6.The menu information, encompassing ingredients and allergen details, is diligently curated to
                        provide you with the most accurate information available. Nevertheless, it is important to note
                        that cross-contamination may occur, and we cannot assure the complete absence of allergens in
                        our food. For your safety, please inform our staff of any allergies or dietary restrictions
                        before placing your order. Your well-being is our priority, and we are committed to making your
                        dining experience enjoyable and worry-free.
                        <br/><br/>
                        7.Food Allergy Warning: Our hotpot ingredients may contain common allergens, including peanuts,
                        soy, seafood, and nuts. To ensure your safety, kindly inform our staff of any allergies before
                        dining to prevent potential harm.
                        <br/><br/>
                        8.Hotpot Safety Advisory: As hotpot involves cooking with simmering broth and utensils, we urge
                        you to exercise caution to prevent burns or accidents.
                        <br/><br/>
                        9.For the safety of all guests, particularly children, we recommend that adults supervise them
                        closely during hotpot sessions.
                        <br/><br/>
                        10.Hotpot Broth Caution: Our hotpot broth may be hot and spicy. Please assess your heat
                        tolerance and make adjustments accordingly. For those with a low tolerance for spicy food, we
                        recommend opting for a separate mild broth.
                        <br/><br/>
                        11.Nutritional Note: While we are dedicated to offering fresh and high-quality ingredients, it's
                        important to note that the cooking process in hotpot may lead to a slight loss of nutritional
                        content. We encourage you to maintain a balanced diet to ensure your nutritional needs are
                        adequately met.
                        <br/><br/>
                        12.Cooking Guidelines: Each ingredient is accompanied by a suggested ideal cooking time, which
                        serves as a reference. The actual hotpot cooking time is contingent upon the boiling water
                        temperature. The recommended cooking duration for hotpot ingredients begins once the water or
                        broth in the pot reaches a boil. To uphold food safety standards, we advise thorough cooking of
                        all items and caution against overcrowding the pot, as this may lead to uneven heat
                        distribution. Please use distinct sets of chopsticks and utensils for handling raw ingredients
                        and cooked food to prevent cross-contamination. Your adherence to these guidelines ensures a
                        safe and delightful hotpot experience.
                        <br/><br/>
                        13.Consumption Advisory: The ingestion of raw or undercooked meats, seafood, or other
                        ingredients in hotpot may elevate the risk of foodborne illnesses. To mitigate this risk, we
                        strongly recommend thoroughly cooking all ingredients before consumption. Your safety is
                        paramount, and adhering to proper cooking practices ensures a safe and enjoyable dining
                        experience.
                        <br/><br/>
                        14.Hotpot ingredients may include bones, shells, or other inedible materials. Exercise caution
                        while consuming and promptly remove any inedible items.
                        <br/><br/>
                        15.Health Conditions: If you have any specific diseases or health issues, such as immune
                        disorders or gastrointestinal problems, please consult a doctor before dining. Choose
                        ingredients carefully and exercise caution to ensure they are suitable for your health
                        condition.
                        <br/><br/>
                        16.Food Cross-Contamination Advisory: At DAHU Hotpot restaurant, our diverse selection of
                        ingredients and condiments increases the possibility of cross-contamination. Prioritize the
                        freshness and safety of ingredients during your dining experience. Employ separate chopsticks
                        and utensils when handling raw and cooked food to ensure a hygienic meal.
                        <br/><br/>
                        17.Promoting Healthy Choices: While DAHU Hotpot provides a range of ingredients such as meat,
                        vegetables, and seafood, we cannot assure that all meet strict healthy eating standards. We
                        encourage you to make mindful food selections based on your individual dietary needs.
                        <br/><br/>
                        18.Whilst we will make every effort to ensure that the information relating to our products and
                        the corresponding prices are correct, such product information, pricing, and images available at
                        our website are for general reference only. We reserve the right to vary prices and all product
                        information without prior notice. Availability of our products may also vary at our individual
                        restaurants.
                        <br/><br/>
                        19.Caution Regarding Hotpot Heat: The soup base and ingredients in the hotpot can reach high
                        temperatures, posing a risk of burns. Exercise care to avoid any accidental burns. Additionally,
                        refrain from using excessively large bowls or containers to prevent scalding incidents.<br/>Storage
                        Responsibility: Please take care of your personal belongings and valuables. DAHU hotpot
                        is not responsible for any lost, damaged, or stolen items.
                        <br/><br/>
                        20.Disclaimer and Disclosure for 24 Hours Video Surveillance In Use: Our restaurant utilizes
                        surveillance cameras to enhance the safety and security of our patrons, employees, and property.
                        By entering and remaining on the premises, you acknowledge and consent to the use of
                        surveillance cameras in accordance with the terms outlined below<br/>Surveillance Purpose: The
                        primary purpose of the cameras is to maintain a safe and secure
                        environment within the restaurant premises, safeguarding against theft, vandalism, and ensuring
                        the well-being of individuals.
                        <br/><br/>
                        21.Public Areas Coverage: Cameras are strategically positioned to monitor public areas within
                        the restaurant, such as entrances, dining areas, and waiting areas. Private areas such as
                        restrooms are explicitly excluded from surveillance to respect privacy.
                        <br/><br/>
                        22.Data Storage: Recorded footage is stored securely and is accessed only for legitimate safety
                        and security reasons. The data is retained for a limited period, and access is restricted to
                        authorized personnel.
                        <br/><br/>
                        23.Privacy Consideration: While our aim is to prioritize safety, we respect the privacy of our
                        patrons. Conversations and private interactions are not actively monitored, and efforts are made
                        to balance security needs with privacy concerns.
                        <br/><br/>
                        24.No Audio Recording: Please be advised that our cameras are focused solely on video recording.
                        Audio recording is not implemented within the premises to protect the privacy of our guests.
                        <br/><br/>
                        25.Notification and Compliance: This notice serves as a disclosure of our camera usage. By
                        entering the premises, you acknowledge and agree to the terms outlined herein. Non-compliance
                        with our policies may result in refusal of entry or other appropriate action.
                        <br/><br/>
                        26.Contact Information: If you have any questions, concerns, or require clarification regarding
                        our camera usage, please contact our management or designated personnel. We are committed to
                        addressing your inquiries promptly.
                        <br/><br/>
                        27.Disclaimer of Liability: While every effort is made to ensure the security of our premises,
                        we disclaim liability for any unforeseen events or circumstances that may occur despite our
                        security measures.
                        <br/><br/>
                        28.Your patronage and understanding contribute to maintaining a safe and enjoyable dining
                        experience. We appreciate your cooperation and look forward to serving you.
                        <br/><br/>
                        29.All-You-Can-Eat Hotpot: Please be advised that additional charges may be incurred for food
                        waste, with a fee of $10 per 500g of excess waste.
                        <br/><br/>
                        30.Prior to handling hotpot ingredients, be sure to wash your hands thoroughly both
                        before and after. Ensure that the hotpot ingredients are fresh and appropriately stored.
                        <br/><br/>
                        31.Adhere to the suggested cooking times and follow the provided instructions for each
                        ingredient.
                        <br/><br/>
                        32.Maintain the hotpot broth at a gentle simmer to ensure thorough cooking of the ingredients.
                        <br/><br/>
                        33.Indulge in hotpot in moderation, paying attention to your body's signals of fullness.
                        <br/><br/>
                        34.Stay well-hydrated by drinking plenty of fluids throughout the hotpot meal. We wish you a
                        safe and delightful hotpot experience!

                        <br/><br/><br/><br/> <br/><br/><br/><br/>

                        謝謝您光顧大虎火鍋重慶火鍋(原創)店 多倫多。在此誠摯提醒您，請在享用火鍋前仔細閱讀以下建議和免責聲明
                        <br/><br/>
                        1.大虎重慶火鍋(原創)店 旗艦店 位於47 寶雲街, 多倫多。
                        <br/><br/>
                        2.加拿大大虎重慶火鍋旗艦店是獨一無二的海外品牌。大虎重慶火鍋以麻而不嗆、辣而不燥、香而不膩、醇而回味而聞名。值得注意的是，除此之外所有與此類似或者添加前后缀的品牌均與我们無關。我們的大虎重慶火鍋旗艦店位於多倫多寶雲街47號。本店與其他本地餐廳沒有任何合作經營關聯或關係。我們擁有大虎的完整商標權，對於任何企業或個人侵犯大虎註冊商標專業權的行為，我們保留追究相關主體法律責任的權利。
                        <br/><br/>
                        3.加拿大大虎重慶火鍋唯一官方網站：www.dahuhotpot.ca 官方郵箱：info@dahuhotpot.ca
                        官方社交媒體平台<br/>
                        臉書 Facebook：https://www.facebook.com/dahuhotpotca/<br/>
                        Instagram：https://www.instagram.com/dahuhotpotca/
                        Twitter：https://www.twitter.com/dahuhotpotca<br/>
                        小紅書 (Little Redbook) ID: DAHUHOTPOT<br/>
                        <br/><br/>
                        4.餐飲服務：我們的店內提供自助式的服務提供火鍋任食以及單點服務，您有責任保持個人衛生，並遵從店內的衛生規則，以確保食品安全。
                        <br/><br/>
                        5. 所有訂單均透過我們的網站下單，並將被導向外部連結 order.mrsdigi.com。一旦您的訂單提交給
                        order.mrsdigi.com，您將收到一則簡訊，以確認您提供的電子郵件地址或手機號碼。如未確認或回應
                        order.mrsdigi.com，可能導致訂單被取消，且不會進行處理。
                        <br/><br/>
                        6.過敏原資訊:
                        我們提供的菜單資訊，包括成分和過敏原資訊，是我們所知的最準確的。然而，請注意可能發生交叉污染，我們無法保證食物中不存在過敏原。請在點餐前告知我們的員工有關任何過敏或飲食限制的情況。
                        如果有任何幫助，歡迎聯繫我們的服務員。願您在大虎火鍋享受不同的火鍋風味！
                        <br/><br/>
                        7.食品過敏風險：我們的火鍋食材可能含有常見的過敏原，如花生、大豆、海鮮、堅果等。請在用餐前告知服務人員您的過敏情況，以避免可能的危害。
                        <br/><br/>
                        8.火鍋涉及使用熱湯和器具進行烹飪，請小心避免燙傷或意外。在火鍋過程中，兒童應在成人監護下，以避免意外發生。
                        <br/><br/>
                        9.火鍋湯底可能會很辣。請根據自己的辣度承受能力進行調整。建議辣食不佳的客人可以選擇單獨湯鍋及選擇溫和的湯底。
                        <br/><br/>
                        10.餐飲服務：我們的店內提供自助式的服務提供火鍋任食以及單點服務，您有責任保持個人衛生，並遵從店內的衛生規則，以確保食品安全。
                        <br/><br/>
                        11.營養價值：雖然我們盡力提供新鮮和高品質的食材，但火鍋中的營養成分可能因烹飪過程而有所損失。請保持均衡飲食，以確保營養需求的滿足。
                        <br/><br/>
                        12.烹飪時間：每種食材都附有建議的理想烹飪調理時間，烹調理時間表僅作參考。火鍋烹飪時間是以沸水溫度為基準的。火鍋食材烹飪參考之調理時間表以待鍋中的水或湯煮沸後計算。為確保食物安全，食物建議必須徹底煮熟，避免將太多食物放入鍋中，否則會導致熱量分布不均。請使用兩套獨立的筷子和餐具來處理/夾取生鮮食材與熟食。
                        <br/><br/>
                        13.在火鍋中食用生或未煮熟的肉類、海鮮或其他食材可能會增加食物中毒的風險。建議在食用前將所有食材煮熟.
                        火鍋的食材可能含有骨頭、殼或其他不可食用的物質。在用餐時請小心，並將不可食用物品去除。
                        <br/><br/>
                        14.健康狀況：如果您患有特殊疾病或健康問題，如免疫系統紊亂、胃腸問題等，請在就餐前咨詢醫生的建議，並謹慎選擇適合的食材。
                        <br/><br/>
                        15.食品交叉污染：火鍋店內提供多種食材和調味料，有可能存在交叉污染的風險。請在就餐前確認食材的新鮮度和安全性。使用兩套分開的筷子和餐具處理生食和熟食。
                        <br/><br/>
                        16.寄希望健康飲食：火鍋店提供了各種食材，包括肉類、蔬菜、海鮮等，但並不能保證所有食材都符合健康飲食的要求。請根據您的需求合理選擇食物。
                        雖然我們將盡一切努力確保與我們的產品相關的資料和價格是正確的，但在本網站中提供的產品信息、價格和圖像僅供一般參考。我們保留在不另行通知的情況下更改價格和產品資料的權利。我們各個餐廳的產品供應情況也可能有所不同。
                        <br/><br/>
                        17.火鍋熱度：火鍋內的湯底和食材有可能非常熱，請小心不要燙傷自己。同時，避免使用過大的碗或容器，以免導致燙傷。
                        <br/><br/>
                        18.物品保管責任： 請您自行妥善管理隨身和貴重物品，對於任何丟失、損壞或被盜的物品，大虎重慶火鍋概不負責。
                        <br/><br/>
                        19.聲明與安全攝影機使用披露：本餐廳使用監視攝影機，以提升顧客、員工和財產的安全和保安。進入並停留在本場所即表示您承認並同意根據以下條款使用監視攝影機。
                        <br/><br/>
                        21.監視目的：攝影機的主要目的是在餐廳場所內維持安全和安全環境，防範盜竊、破壞行為，並確保個人的安全和福祉。
                        <br/><br/>
                        22.公共區域覆蓋：攝影機被戰略性地放置以監視餐廳內的公共區域，如入口、用餐區和等候區。為尊重隱私，如洗手間等私人區域明確排除在監視之外。
                        <br/><br/>
                        23.資料存儲：錄製的畫面被安全地存儲，僅出於合法的安全和保安原因才能訪問。數據會被保留一定的時間，並且僅授權人員可訪問。
                        <br/><br/>
                        24.隱私考慮：儘管我們的目標是優先考慮安全，但我們尊重顧客的隱私。對話和私人互動不會被主動監控，我們努力在安全需求和隱私關懷之間取得平衡。
                        <br/><br/>
                        25.無音頻錄製：請注意，我們的攝影機僅專注於視頻錄製。為了保護客人的隱私，在場所內不實施音頻錄製。
                        <br/><br/>
                        26.通知與遵守：本通知作為我們使用攝影機的披露。進入場所即表示您承認並同意以下條款。不遵守我們的政策可能導致拒絕入場或採取其他適當行動。
                        <br/><br/>
                        27.聯絡資訊：如果您有任何問題、疑慮或需要有關我們攝影機使用的澄清，請聯繫我們的管理層或指定人員。我們致力於迅速解決您的疑問。
                        <br/><br/>
                        28.免責聲明：儘管我們已經采取一切措施確保場所的安全，但對於可能發生的任何不可預見的事件或情況，我們概不負責。
                        <br/><br/>
                        29.您的光臨和理解有助於維護一個安全而愉快的用餐體驗。感謝您的合作，期待為您服務。
                        <br/><br/>
                        30.堂食任食火鍋：浪費食物會有額外收費，每500克按$10/500 克收費
                        <br/><br/>
                        31.建議：在處理火鍋食材之前和之後，請徹底洗手。
                        <br/><br/>
                        32.確保火鍋食材新鮮並妥善存放。
                        <br/><br/>
                        33.遵循每種食材的推薦烹飪時間和方法。
                        <br/><br/>
                        34.將火鍋湯底保持溫和地煨，以確保食材煮熟。
                        <br/><br/>
                        35.適量享用火鍋，並傾聽身體飽腹信號。
                        <br/><br/>
                        36. 在享用火鍋餐時要多飲水，保持水分供給。我們希望您度過一個安全愉快的火鍋體驗！

                        <br/><br/><br/><br/> <br/><br/><br/><br/>


                        Disclaimer for DAHU VIP membership Card(s):
                        <br/><br/>
                        Thank you for choosing DAHU Hotpot Flagship in Canada. We would like to take a moment to
                        highlight important details regarding the purchase of your DAHU VIP membership Card(s).
                        Additionally, we want to ensure you are well-informed about disclaimers related to the usage of
                        DAHU VIP membership Card(s), coupons, and cash vouchers.
                        <br/><br/>
                        DAHU VIP membership Card(s) Prestige rewards program terms and condition:
                        <br/><br/>
                        1. DAHU VIP membership cards are exclusively issued at 47 Baldwin Toronto location. These cards
                        are intended for in-store use only and are non-transferable. Join as a DAHU Hotpot VIP member
                        for $19.99 and enjoy our monthly exclusive membership offers.
                        <br/><br/>
                        2.Kindly inform us promptly in the event of loss, damage, or theft of your DAHU VIP membership
                        card(s). We will assess and manage the situation based on the information you provide. In case
                        of inaccurate information, we reserve the right to withhold replacement. Please be advised that
                        we do not assume responsibility for any losses or damages incurred as a result.
                        <br/><br/>
                        3.DAHU VIP membership cards are non-refundable and cannot be exchanged for cash. They are solely
                        intended for use in offsetting corresponding expenses or for consumption purposes.
                        <br/><br/>
                        4.For every $10 spent, you will earn ONE (1) DAHU rewards point. Each ONE (1) DAHU rewards point
                        is redeemable for $1 store credit (Please note that points cannot be exchanged for cash). If you
                        do not use the rewards points accumulated on your DAHU VIP membership card(s) within one year,
                        they may automatically expire.
                        <br/><br/>
                        5.To redeem any promotional items, DAHU VIP membership card(s) must be presented to
                        our staff on each visit.
                        <br/><br/>
                        6.We retain the authority to modify, revise, or terminate the terms and condition the use of
                        DAHU VIP membership Card(s). Any adjustments to the pertinent terms will be made and
                        communicated to you as needed.
                        <br/><br/>
                        7.Loading Value: The DAHU VIP membership Card(s) issued by DAHU Hotpot Canada does not have
                        loading value or cash value.
                        <br/><br/>
                        8.Refund Policy: DAHU VIP membership Card(s) are non-refundable and cannot be cashed out. The
                        DAHU VIP membership Card(s) do not have an expiration date.
                        <br/><br/>
                        9.Discount Details: The DAHU VIP membership Card(s) may offer discounts, complimentary food,
                        goods, multiplied points, etc. The specific discount content may vary based on different member
                        activities.
                        <br/><br/>
                        10.Points Expiration: DAHU VIP membership Card(s) may have limitations, and rewards point(s)
                        must be utilized within one (1) year. Any unused rewards point(s) after expiration may become
                        invalid.
                        <br/><br/>
                        11.Other Terms: The use of DAHU VIP membership Card(s) may also be subject to additional general
                        terms, including but not limited to no return or exchange of goods, non-transferability to
                        others, etc. Members are advised to thoroughly read and comprehend these terms.
                        <br/><br/>
                        12.DAHU VIP membership cards cannot be combined with other promotional offers or discounts.
                        Additionally, they are not applicable in conjunction with any vouchers or discounted packages.
                        <br/><br/>
                        13.Upon the issuance of the DAHU VIP membership card, any cash vouchers or coupons provided on
                        the same day will be valid only on the subsequent visit.
                        <br/><br/>
                        14.DAHU Cash vouchers cannot be accumulated or used repeatedly for multiple transactions. Using
                        multiple cash vouchers during the same visit or transaction is not permissible.
                        <br/><br/>
                        15.DAHU Cash vouchers have no cash value and cannot be exchanged for cash
                        <br/><br/>
                        16.We reserve the right to add to, modify or remove the terms and conditions of these Terms of
                        Use, including any documents or policies incorporated herein, at any time and without prior
                        notice.
                        <br/><br/>
                        17.Protection of Personal Data: Safeguarding your personal information is our primary business
                        principle. Without your explicit consent, we firmly commit not to disclose your personal data to
                        any third party unrelated to the services provided on this site.
                        <br/><br/>
                        18.You are advised to review our latest Terms of Use when accessing this website and any of its
                        pages, in which case you shall be deemed to agree to these Terms of Use, and accept and agree to
                        be bound by the same.
                        <br/><br/>
                        19.DAHU reserves the right to periodically modify and adjust the terms of service. We advise you
                        to regularly check the website announcements, as changes will be effective from the date of
                        announcement. By continuing to use this service after any modifications, it will be considered
                        that you comprehend, accept, and agree to the revised terms of service.
                        <br/><br/>
                        20. DAHU Hotpot Canada reserves the right to make the final decision.
                        <br/><br/>
                        Thank you for taking the time to read and comprehend our disclaimer. Please exercise discernment
                        before acquiring DAHU VIP membership Card(s). Should you have any inquiries regarding the DAHU
                        VIP membership Card(s), please don't hesitate to reach out to our customer service team. We
                        appreciate your understanding and support!

                        <br/><br/><br/><br/><br/><br/><br/><br/>

                        大虎重慶火鍋 DAHU VIP 會員卡/尊貴獎賞計畫 / 現金券條款及細則聲明
                        <br/><br/>
                        1. DAHU VIP 會員卡僅在多倫多大虎重慶火鍋 Baldwin 分店專屬發行。僅供店內使用，且不可轉讓。加入 DAHU
                        Hotpot VIP 會員需支付 $19.99 辦理會員, 即可享受我們每月獨家的會員優惠。
                        <br/><br/>
                        2. 若您的 DAHU VIP
                        會員卡遺失、損壞或被盜，請及時通知我們。我們將根據您提供的資訊評估和處理情況。若資訊不正確，我們保留拒絕更換的權利。請注意，我們對因此造成的任何損失或損害不負責。
                        <br/><br/>
                        3. DAHU VIP 會員卡不可退款，也不能兌換現金。它們僅用於抵消相應的開支或消費。
                        <br/><br/>
                        4. 每消費 $10，您將獲得獎賞一（1）DAHU 獎勵積分。每一（1）個 DAHU 獎勵積分可兌換 $1
                        的店內餐飲回贈（請注意，積分不能兌換現金）。如果您在一年內未使用 DAHU VIP 會員卡上積累的積分，它們可能會自動失效。
                        <br/><br/>
                        5. 要兌換任何促銷品，您必須在每次光臨大虎向我們的工作人員展示/出示DAHU VIP 會員卡。
                        <br/><br/>
                        6. 我們保留修改、修訂或終止 DAHU VIP 會員卡使用條款及細則的權利。對相關條款的任何調整將根據需要進行並通知您。
                        <br/><br/>
                        7. 儲值金額：由 DAHU Hotpot Canada 發行的 DAHU VIP 會員卡沒有儲值金額或現金價值。
                        <br/><br/>
                        8. 退款政策：DAHU VIP 會員卡不可退款，也不能兌換現金。DAHU VIP 會員卡沒有到期日。
                        <br/><br/>
                        9. 折扣詳情：DAHU VIP 會員卡尊享折扣、免費食品、商品、限時優惠、積分倍增等。具體的折扣內容可能因會員活動,
                        每月獨家的會員優惠而有所不同。
                        <br/><br/>
                        10. 積分到期：DAHU VIP 會員卡可能有使用限制，獎勵積分必須在一（1）年內使用。過期後未使用的獎勵積分可能會失效。
                        <br/><br/>
                        11. 其他條款：DAHU VIP 會員卡的使用可能還受其他一般條款的約束，包括但不限於商品不可退換、不可轉讓給他人等。建議會員仔細閱讀和理解這些條款。
                        <br/><br/>
                        12. DAHU VIP 會員卡不能與其他折扣或推廣活動中重複使用。此外，它們不適用於與任何優惠券或折扣套餐組合使用。
                        <br/><br/>
                        13. DAHU VIP 會員卡發行當天所提供的任何現金券或優惠券僅在下一次造訪時有效。
                        <br/><br/>
                        14. DAHU 現金券不能累積使用，且不能在同一天或交易中重複使用。
                        <br/><br/>
                        15. DAHU 現金券無現金價值，且不能兌換成現金。不具任何財產價值。該積分不得兌換現金或多卡合併使用。
                        <br/><br/>
                        16. 我們保留隨時且無需事先通知添加、修改或刪除這些使用條款及細則的權利，包括任何在此處納入的文件或政策。
                        <br/><br/>
                        17.個人資料安全保障: 保護您的個人資料是我們的首要經營原則。在未經您的同意之下，我們堅決不會將您的個人資料提供給任何與本站服務無關的第三方。
                        <br/><br/>
                        18. 您在訪問本網站及其任何頁面時，建議您查閱我們的最新使用條款。在這種情況下，您將被視為同意這些使用條款，並同意接受並受其約束。
                        <br/><br/>
                        19. 大虎將不定期修改和調整本服務的權益。建議您隨時關注網站的公告，變更內容將於公告日生效。若您在變更或修改後繼續使用本服務，即視為您已瞭解、接受並同意修改後的服務條款。
                        <br/><br/>
                        20. 大虎重慶火鍋加拿大保留最終決定權。
                        <br/><br/>
                        感謝您閱讀並理解我們的免責聲明。如果您對大虎重慶火鍋(原創)VIP會員卡 / 積分優惠的任何內容有疑問，請隨時與我們的客戶服務團隊聯繫，我們將竭誠為您解答。感謝您的理解與支持！

                    </div>


                </div>
                <Footer></Footer>
            </div>
        );
    }
}


export default Index;
