// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/lantern background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lantern-section {
    position: relative;
    width: 100%;
    height: 80vh;
    background-size: 100%;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    margin-bottom: 30px;
}

.overlay {
    /*background-color: rgba(0, 0, 0, 0.5); !* Dark overlay for readability *!*/
    padding: 20px;
    text-align: left;
}

.membership-body h1 {
    font-size: 3em;
    margin: 0 0 10px;
    text-transform: uppercase;
    color: white;
}

.membership-body p {
    font-size: 1.2em;
    margin: 5px 0;
    color: white;
}

.red {
    color: #e21c21;
}

.small {
    color: white;
    font-size: 9px;
}

.cta-button-terms-and-conditions {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 1em;
    text-transform: uppercase;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.cta-button-terms-and-conditions:hover {
    background-color: #ccc;
}

.divider{
    padding-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Membership/index.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,mDAAsD;IACtD,mBAAmB;AACvB;;AAEA;IACI,2EAA2E;IAC3E,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,iCAAiC;AACrC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.lantern-section {\n    position: relative;\n    width: 100%;\n    height: 80vh;\n    background-size: 100%;\n    background-position: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff;\n    background: url('../../images/lantern background.png');\n    margin-bottom: 30px;\n}\n\n.overlay {\n    /*background-color: rgba(0, 0, 0, 0.5); !* Dark overlay for readability *!*/\n    padding: 20px;\n    text-align: left;\n}\n\n.membership-body h1 {\n    font-size: 3em;\n    margin: 0 0 10px;\n    text-transform: uppercase;\n    color: white;\n}\n\n.membership-body p {\n    font-size: 1.2em;\n    margin: 5px 0;\n    color: white;\n}\n\n.red {\n    color: #e21c21;\n}\n\n.small {\n    color: white;\n    font-size: 9px;\n}\n\n.cta-button-terms-and-conditions {\n    background-color: #fff;\n    color: #000;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    margin-top: 20px;\n    font-size: 1em;\n    text-transform: uppercase;\n    border-radius: 5px;\n    transition: background-color 0.3s;\n}\n\n.cta-button-terms-and-conditions:hover {\n    background-color: #ccc;\n}\n\n.divider{\n    padding-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
