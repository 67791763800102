import React from "react";
import "./index.css";
import DahuLargeLogo from "../../images/Dahu_NewLogopng.png";
import OriginalLogo from "../../images/Original DAHU HOTPOT.png";
import DahuSlogan from "../../images/DAHU SLOGAN 匠心品質、真情至上.jpg";
import Header from "../Header";
import Footer from "../Footer";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div>
                <Header></Header>

                <div className="body vertical-align">
                    <img src={DahuLargeLogo} alt="Instagram"/>
                    <img src={OriginalLogo} alt="Instagram"/>
                    <div className="slogan">Ingenuity in Quality &nbsp; Sincerity Supreme</div>
                    <img src={DahuSlogan} alt="Instagram"/>

                    <div className="buttons">
                        <a href="https://order.mrsdigi.com/035411231109003/42644362-8145-518b-a271-3c0afb8fe153" className="cta-button">ONLINE ORDER</a>
                        <a href="https://order.mrsdigi.com/035411231109003/42644362-8145-518b-a271-3c0afb8fe153" className="cta-button">TAKEOUT</a>
                    </div>

                    <div className="slogan">ORIGINAL</div>
                    <div className="slogan">DAHU HOTPOT ®FLAGSHIP IN CANADA</div>
                    <div className="slogan slogan-small">LOCATION AT DOWNTOWN TORONTO</div>
                    <div className="slogan slogan-small">47 BALDWIN STREET TORONTO</div>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}


export default Index;
