import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./views/Home";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Helmet} from "react-helmet";
import Membership from "./views/Membership";
import Promotion from "./views/Promotion";
import HourAndPrice from "./views/HourAndPrice";
import Disclaimer from "./views/Disclaimer";
import favicon from "./images/favicon.ico";
import Favicon from "react-favicon";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        // errorElement: <Home />,
    },
    {
        path: "*",
        element: <Home/>,
    },
    {
        path: "prestige-rewards",
        element: <Membership/>,
    },
    {
        path: "dahupromotion",
        element: <Promotion/>,
    },
    {
        path: "hoursandprice",
        element: <HourAndPrice/>,
    },
    {
        path: "disclaimer",
        element: <Disclaimer/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Favicon url={favicon}/>
        <Helmet>
            <title>{"大虎重慶火鍋 ® DAHU Hotpot Flagship in Canada"}</title>
            <meta name="description" content="THE ORIGINAL DAHU HOTPOT FROM CHONGQING"/>
        </Helmet>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
