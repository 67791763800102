import React from "react";
import "./index.css";

import Header from "../Header";
import Footer from "../Footer";

import LanternBackground from "../../images/lantern2.jpg";


class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <div>
                <Header></Header>
                <div className="summer-deals-container">
                    <div className="image-section" style={{backgroundImage: `url(${LanternBackground})`}}>
                        {/* Empty, just for background image */}
                    </div>
                    <div className="text-section">
                        <h1>Summer Deals</h1>
                        <p>All-You-Can-Eat Hot Pot at 5% OFF</p>
                        <p>From Monday to Sunday!</p>
                        <p>Promotion valid on <span className="highlight">Cash Payment Only.</span></p>
                        <p>Enjoy the 5% off discount with cash payment. Not applicable to all promotional set meals or
                            in conjunction with any other special promotions/deals.</p>
                        <p>All rights reserved by DAHU HOTPOT Flagship in Canada</p>
                        <p>星期一 至 星期日<br/>
                            <span className="highlight">現金優惠價</span><br/>
                            全日任食火鍋 95折 現金優惠價。<br/>
                            不包含所有套餐或優惠活動。<br/>
                            大虎火鍋保留最終決定權/暫停優惠活動。</p>
                        <a href="prestige-rewards" className="cta-button">Member's Deals</a>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}


export default Index;
